import styled from "@emotion/styled";
import { gsap, Linear, Power4 } from "gsap";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { SVGUniqueID } from "react-svg-unique-id";

import useGsapSelector from "../../hooks/useGsapSelector";
import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import { rotatingButton } from "../Typography";

const appearances = {
  default: {
    border: theme.color("primary.base"),
    textColor: theme.color("primary.base"),
    background: "transparent",
    hover: {
      border: theme.color("text.light"),
      textColor: theme.color("primary.base"),
      background: theme.color("text.light"),
    },
  },
  light: {
    border: theme.color("text.light"),
    textColor: theme.color("text.light"),
    background: "transparent",
    hover: {
      border: theme.color("text.light"),
      textColor: theme.color("primary.base"),
      background: theme.color("text.light"),
    },
  },
};

const rotation = {
  left: {
    transform: "rotate(180deg)",
  },
  right: {
    transform: "rotate(0deg)",
  },
  up: {
    transform: "rotate(-90deg)",
  },
  down: {
    transform: "rotate(90deg)",
  },
};

const Wrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 30px;
  background-color: ${({ appearance }) => appearances[appearance].background};
  border: 2px solid ${({ appearance }) => appearances[appearance].border};
  border-radius: 50%;
  ${mq("2")} {
    padding: 40px;
  }
`;

const SvgWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  & > svg {
    opacity: 0;
    & > text {
      ${rotatingButton};
    }
  }
`;

const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transform: ${({ arrowDirection }) => rotation[arrowDirection].transform};
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const RotatingButton = ({ label, arrowDirection, appearance, ...props }) => {
  const [labelLength, setLabelLength] = useState(120);
  const [labelRepeats, setLabelRepeats] = useState(10);
  const containerRef = useRef();
  const buttonRef = useRef();
  const svgWrapperRef = useRef();
  const textPathRef = useRef();
  const pathRef = useRef();
  const [q, svgElement] = useGsapSelector();
  const [aq, arrowElement] = useGsapSelector();

  const rotationTimeline = useRef();
  const hoverTimeline = useRef();

  useEffect(() => {
    /* The code below does the following:
    1. Calculates the length of a label.
    2. Calculates the length of the path.
    3. If the (label * repeats = textPath) is longer than the path, then the label repeats are reduced.
    4. If the textPath is shorter than the path, then the spacing between labels is increased. */
    const textLength = Math.floor(textPathRef.current.getComputedTextLength());
    const pathLength = Math.ceil(pathRef.current.getTotalLength());
    let repeats = labelRepeats;
    if (textLength * labelRepeats > pathLength) {
      const remainder = textLength * labelRepeats - pathLength;
      const amountOfRepeatsToRemove = Math.ceil(remainder / textLength);
      repeats = labelRepeats - amountOfRepeatsToRemove;
      setLabelRepeats(repeats);
    }
    if (textLength * repeats < pathLength) {
      setLabelLength(
        textLength + (pathLength - textLength * repeats) / repeats
      );
    }
  }, []); // eslint-disable-line

  const playShow = () => {
    hoverTimeline.current.reversed(!hoverTimeline.current.reversed());
  };

  const playHover = () => {
    rotationTimeline.current.play();
    gsap.to(rotationTimeline.current, {
      timeScale: 1,
      duration: 1,
      overwrite: true,
    });
    playShow();
  };

  const endHover = () => {
    gsap.to(rotationTimeline.current, {
      timeScale: 1,
      duration: 1,
      overwrite: true,
      onComplete() {
        rotationTimeline.current.pause();
      },
    });
    playShow();
  };

  useLayoutEffect(() => {
    rotationTimeline.current = gsap.timeline();
    rotationTimeline.current
      .to(
        svgElement.current,
        {
          duration: 10,
          rotate: 360,
          ease: Linear.easeNone,
          repeat: -1,
        },
        0
      )
      .timeScale(0);
    rotationTimeline.current.pause(0);
    return () => {
      rotationTimeline.current.kill();
    };
  }, []); // eslint-disable-line

  useLayoutEffect(() => {
    hoverTimeline.current = gsap.timeline();
    hoverTimeline.current.to(
      svgElement.current,
      {
        duration: 1,
        opacity: 1,
        ease: Linear.easeNone,
      },
      0
    );
    hoverTimeline.current.to(
      buttonRef.current,
      {
        duration: 1,
        backgroundColor: appearances[appearance].hover.background,
        borderColor: appearances[appearance].hover.border,
        ease: Power4.easeInOut,
      },
      0
    );
    hoverTimeline.current.to(
      q("textPath"),
      {
        duration: 1,
        attr: { fill: appearances[appearance].hover.textColor },
        ease: Power4.easeInOut,
      },
      0
    );
    hoverTimeline.current.to(
      aq("line, polyline"),
      {
        duration: 1,
        attr: { stroke: appearances[appearance].hover.textColor },
        ease: Power4.easeInOut,
      },
      0
    );
    hoverTimeline.current.reversed(true);
    return () => {
      hoverTimeline.current.kill();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const containerElement = containerRef && containerRef.current;
    if (!containerElement) {
      return;
    }
    containerElement.addEventListener("mouseenter", playHover);
    containerElement.addEventListener("mouseleave", endHover);
    return () => {
      containerElement.removeEventListener("mouseenter", playHover);
      containerElement.removeEventListener("mouseleave", endHover);
    };
  });

  return (
    <Wrapper ref={containerRef} {...props}>
      <ButtonWrapper ref={buttonRef} appearance={appearance}>
        <SvgWrapper ref={svgWrapperRef} arrowDirection={arrowDirection}>
          <SVGUniqueID>
            <Svg ref={svgElement} viewBox="0 0 140 140">
              <path
                ref={pathRef}
                fill="transparent"
                id="textPath"
                d="M70,135c-35.9,0-65-29.1-65-65S34.1,5,70,5s65,29.1,65,65S105.9,135,70,135z"
              />
              <text>
                {[...Array(labelRepeats)].map((x, i) => (
                  <textPath
                    key={i}
                    ref={textPathRef}
                    startOffset={i * labelLength}
                    xlinkHref="#textPath"
                    alignmentBaseline="hanging"
                  >
                    {label}
                  </textPath>
                ))}
              </text>
            </Svg>
          </SVGUniqueID>
        </SvgWrapper>

        <ArrowWrapper arrowDirection={arrowDirection}>
          <Svg ref={arrowElement} viewBox="0 0 60 60">
            <line
              x1="58"
              y1="30"
              x2="2"
              y2="30"
              strokeWidth="2"
              stroke={appearances[appearance].textColor}
            />
            <polyline
              points="48,20 58,30 48,40"
              fill="none"
              stroke={appearances[appearance].textColor}
              strokeWidth="2"
            />
          </Svg>
        </ArrowWrapper>
      </ButtonWrapper>
    </Wrapper>
  );
};

RotatingButton.propTypes = {
  label: PropTypes.string,
  appearance: PropTypes.oneOf(["default", "light"]),
  arrowDirection: PropTypes.oneOf(["up", "down", "left", "right"]),
};

RotatingButton.defaultProps = {
  appearance: "default",
  label: "lees meer",
  arrowDirection: "right",
};

export default RotatingButton;
