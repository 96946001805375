import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useState } from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Heading from "../Heading";
import Lottie from "../Lottie";
import PillButton from "../PillButton";
import ReplaceableLink from "../ReplaceableLink";
import Text from "../Text";
import { bodyLarge } from "../Typography";

const Item = styled.div`
  background-color: ${theme.color("dark.lighter")};
  padding: ${theme.space("l")} ${theme.space("s")};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  align-items: center;
  gap: 2.75rem;
  transition: background-color 0.3s ease-in-out;
  & .lottie svg path {
    fill: ${({ iconColor }) => iconColor.hex};
  }
  &:hover {
    background-color: ${({ iconColor }) => iconColor.hex};
    color: ${({ hoverColor }) => hoverColor.hex};
    & .lottie svg path {
      fill: ${({ hoverColor }) => hoverColor.hex};
    }
    .button {
      color: ${({ hoverColor }) => hoverColor.hex} !important;
      > div {
        border-color: ${({ hoverColor }) => hoverColor.hex} !important;
      }
    }
    .button svg line,
    .button svg polyline {
      stroke: ${({ hoverColor }) => hoverColor.hex} !important;
    }
  }
  ${mq("2")} {
    display: grid;
    grid-template-columns: 10rem 1fr auto;
  }
`;

const GridItemStyledText = styled(Text)`
  ${bodyLarge};
  position: relative;
  color: inherit;
  margin: 0.5rem 0;
`;

function GridItem({
  title,
  overviewIntro,
  slug,
  iconColor,
  iconColorHover,
  lottieAnimation,
  ...props
}) {
  const [lottiePlaying, setLottiePlaying] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(1);

  const animatePin = (state) => {
    setAnimationSpeed(state ? 1 : 2.5);
    state && setLottiePlaying(true);
  };

  return (
    <ReplaceableLink
      url={slug}
      onMouseEnter={() => animatePin(true)}
      onMouseLeave={() => animatePin(false)}
      {...props}
    >
      <Item iconColor={iconColor} hoverColor={iconColorHover}>
        {lottieAnimation && (
          <Lottie
            loop={true}
            play={lottiePlaying}
            speed={animationSpeed}
            data={lottieAnimation.url}
            className="lottie"
            onLoopComplete={() => {
              animationSpeed === 2.5 && setLottiePlaying(false);
            }}
          />
        )}
        <div>
          <Heading size={3}>{title}</Heading>
          <GridItemStyledText html>{overviewIntro}</GridItemStyledText>
        </div>
        <PillButton className="button" label={" "} animation="disabled" />
      </Item>
    </ReplaceableLink>
  );
}

GridItem.propTypes = {
  title: PropTypes.string,
  overviewIntro: PropTypes.string,
  slug: PropTypes.string,
  iconColor: PropTypes.object,
  iconColorHover: PropTypes.object,
  lottieAnimation: PropTypes.object,
};

export default GridItem;
