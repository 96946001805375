import styled from "@emotion/styled";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import mq from "../../utils/mediaQuery";

const Wrapper = styled(motion.div)`
  position: relative;
  min-height: 150px;
  will-change: transform;
  transform-origin: center center;
  ${mq("2")} {
    min-height: 250px;
  }
`;

ParallaxBox.proptypes = {
  children: PropTypes.array,
  horizontal: PropTypes.bool,
  scrollOffset: PropTypes.array,
  easing: PropTypes.array,
};

function ParallaxBox({
  children,
  horizontal = false,
  scrollOffset = ["-40%", "0%"],
  easing = ["easeInOut"],
  disableATFcalc = false,
  ...props
}) {
  const { scrollY } = useViewportScroll();
  const ref = useRef();
  const [scrollBoundary, setScrollBoundary] = useState([0, 0]);
  const [scrollOffsetState, setScrollOffsetState] = useState(scrollOffset);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const setValues = () => {
      setTimeout(() => {
        if (!ref.current) {
          return;
        }
        const elementTop =
          ref.current.getBoundingClientRect().top + window.scrollY;
        const elementHeight = ref.current.getBoundingClientRect().height;
        const windowHeight = window.innerHeight;

        const scrollStart =
          elementTop < windowHeight ? 0 : elementTop - windowHeight;
        const scrollEnd = elementTop + elementHeight;

        // This currently "fixes" the issue of faulty image positioning when an item is rendered above-the-fold.
        // TODO: calculate the offset based on the position of an element on first render.
        if (!disableATFcalc) {
          if (window.scrollY < 10 && elementTop < windowHeight)
            setScrollOffsetState([
              `-${Math.floor(40 - windowHeight / 100)}%`,
              "0%",
            ]);
        }

        setScrollBoundary([scrollStart, scrollEnd]);
      }, 500);
    };

    setValues();
    window.addEventListener("resize", setValues);

    return () => {
      window.removeEventListener("resize", setValues);
    };
    // eslint-disable-next-line
  }, [ref]);

  const parallaxValue = useTransform(
    scrollY,
    scrollBoundary,
    scrollOffsetState,
    [
      {
        clamp: true,
        ease: easing,
      },
    ]
  );

  return (
    <Wrapper
      ref={ref}
      initial={{
        y: 0,
        x: 0,
      }}
      style={{
        y: horizontal ? 0 : parallaxValue,
        x: horizontal ? parallaxValue : 0,
      }}
      {...props}
    >
      {children}
    </Wrapper>
  );
}

ParallaxBox.propTypes = {
  children: PropTypes.node.isRequired,
  horizontal: PropTypes.bool,
  scrollOffset: PropTypes.array,
  easing: PropTypes.array,
  disableATFcalc: PropTypes.bool,
};

export default ParallaxBox;
