import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import ReactPlayer from "react-player/lazy";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Link from "../Link";
import PillButton from "../PillButton";
import TitleRepeater from "../TitleRepeater";

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${mq("3")} {
    min-height: 100vh;
  }
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  display: block;
  & > video {
    object-fit: cover;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: ${theme.spacing("xl")};
  z-index: 10;
  width: 65%;
  margin: ${theme.spacing("xl")};
`;

const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

function VideoBanner({ title, video, cta, ...props }) {
  return (
    <VideoWrapper {...props}>
      <ContentWrapper>
        <TitleRepeater title={title} />
        {cta && (
          <CtaWrapper>
            <Link to={`/${cta.path}`}>
              <PillButton label={cta.label} appearance="light" />
            </Link>
          </CtaWrapper>
        )}
      </ContentWrapper>
      <Overlay />
      <Player
        className={"videoPlayer"}
        url={video.video.streamingUrl}
        width="100%"
        height="100%"
        playsinline
        muted
        playing
        loop
      />
    </VideoWrapper>
  );
}

VideoBanner.propTypes = {
  title: PropTypes.string.isRequired,
  video: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string,
    video: PropTypes.shape({
      streamingUrl: PropTypes.string,
      thumbJpg: PropTypes.string,
    }),
  }).isRequired,
  cta: PropTypes.object,
};
export default VideoBanner;
