import styled from "@emotion/styled";
import { object, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import ContentWrapper from "../ContentWrapper";
import Link from "../Link";
import RotatingButton from "../RotatingButton";
import { ContactHeading, h5 } from "../Typography";

const FullWidthWrapper = styled.div`
  position: relative;
  background-color: ${theme.color("primary.base")};
  z-index: 0;
  padding: ${theme.spacing("xxl")} 0;
`;

const FlexWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3rem;
`;

const PreTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing("xl")};
  ${mq("3")} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Body = styled.span`
  ${ContactHeading}
  color: ${theme.color("text.light")};
  flex: 1;
`;

const ContactPreTitle = styled.span`
  ${h5}
  color: ${theme.color("text.light")};
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  ${mq("3")} {
    align-items: flex-end;
  }
`;

const ContactBasic = ({ title, cta, body }) => {
  return (
    <FullWidthWrapper>
      <FlexWrapper columns={12}>
        <PreTitleWrapper>
          <ContactPreTitle>{title}</ContactPreTitle>
        </PreTitleWrapper>

        <BodyWrapper>
          <Body>{body}</Body>
          {cta && (
            <CtaWrapper>
              <Link to={`/${cta.path}`}>
                <RotatingButton
                  appearance="light"
                  label={cta.label}
                  arrowDirection="right"
                />
              </Link>
            </CtaWrapper>
          )}
        </BodyWrapper>
      </FlexWrapper>
    </FullWidthWrapper>
  );
};

ContactBasic.propTypes = {
  title: string.isRequired,
  body: string.isRequired,
  cta: object,
};

export default ContactBasic;
