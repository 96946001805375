import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import { customMq } from "../../utils/mediaQuery";
import Heading from "../Heading";
import Link from "../Link";
import PillButton from "../PillButton";
import Text from "../Text";
import { h4 } from "../Typography";
import GridItem from "./GridItem";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing("xxl")};
  margin-bottom: ${theme.spacing("xl")};

  ${customMq(1100)} {
    flex-direction: row;
    margin-top: ${theme.spacing("xxxl")};
    margin-bottom: ${theme.spacing("xxxl")};
  }
`;

const Description = styled.div`
  margin-left: ${theme.space("l")};
  margin-bottom: ${theme.space("l")};
  ${customMq(1100)} {
    max-width: 30%;
  }
`;

const FakeGridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${theme.space("s")} ${theme.space("s")};
  gap: ${theme.space("l")};
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 0;
`;

const StyledText = styled(Text)`
  ${h4};
  position: relative;
`;

const Grid = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  padding: 0 ${theme.space("l")};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

function ProductsGrid({ title, intro, cta, products }) {
  return (
    <Wrapper>
      <Description>
        <FakeGridItem>
          {title && <Heading size={2}>{title}</Heading>}
          {intro && (
            <TextWrapper>
              <StyledText html>{intro}</StyledText>
            </TextWrapper>
          )}
          {cta && (
            <CtaWrapper>
              <Link to={`${cta.path}`}>
                <PillButton label={cta.label} />
              </Link>
            </CtaWrapper>
          )}
        </FakeGridItem>
      </Description>
      {products && (
        <Grid>
          {products.map((productItem) => (
            <GridItem key={productItem.id} {...productItem} />
          ))}
        </Grid>
      )}
    </Wrapper>
  );
}

ProductsGrid.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  cta: PropTypes.object,
  products: PropTypes.array,
};

export default ProductsGrid;
