import styled from "@emotion/styled";
import PropTypes, { shape } from "prop-types";
import React, { useRef } from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import ContentWrapper from "../../ContentWrapper";
import Heading from "../../Heading";
import ImageComponent, { ImageDataType } from "../../ImageComponent";
import ParallaxBox from "../../ParallaxBox";
import RotatingButton from "../../RotatingButton";
import Text from "../../Text";
import { h4 } from "../../Typography";

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.color("primary.base")};
  z-index: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - ${theme.spacing("xxl")} - ${theme.spacing("xl")});
  z-index: 10;
  margin-top: ${theme.spacing("xxl")};
  margin-bottom: ${theme.spacing("xl")};

  ${mq("4")} {
    min-height: calc(
      100vh - ${theme.spacing("xxxl")} - ${theme.spacing("xxl")}
    );
    margin-top: ${theme.spacing("xxxl")};
    margin-bottom: ${theme.spacing("xxl")};
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TitleRepeatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RepeatedHeadingWrapper = styled.div`
  height: calc(${theme.fs("h1")} * 0.875);
  overflow: hidden;

  ${mq("3")} {
    height: calc(${theme.lh("desktop.h1")} * 0.8);
  }
`;

const StyledFirstHeading = styled(Heading)`
  line-height: 0.8 !important;
  color: #fff;
  overflow: hidden;
`;

const StyledStrokedHeading = styled(StyledFirstHeading)`
  line-height: 0.8 !important;
  -webkit-text-stroke: 1px #fff;
  text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
  text-fill: transparent;
`;

const ImageWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
  padding: 0 5%;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
  transform: translateY(-20%);
  ${mq("2")} {
    transform: none;
  }
`;

const ParallaxImage1 = styled(ParallaxBox)`
  z-index: 1;
  position: relative;
  transition: opacity 0.65s cubic-bezier(0.72, 0, 0.28, 1);
  opacity: 0.5;
  width: 85%;
  height: 30%;
  ${mq("2")} {
    width: 50%;
    height: 60%;
  }
`;

const CtaWrapper = styled.div`
  margin-top: ${theme.spacing("xxl")};
`;

const TextContainer = styled.div`
  width: 100%;
  ${mq("3")} {
    width: 70%;
  }
`;

const TextContentWrapper = styled(ContentWrapper)`
  box-sizing: border-box;
  width: 100%;
  ${mq("2")} {
    max-width: min(70vw, 848px);
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 0;
  padding-bottom: ${theme.spacing("l")};

  ${mq("2")} {
    padding-left: ${theme.spacing("xxl")};
  }

  ${mq("3")} {
    padding-left: 0;
    padding-top: ${theme.spacing("xs")};
  }

  ${mq("4")} {
    padding-top: ${theme.spacing("xxl")};
  }
`;

const StyledText = styled(Text)`
  ${h4};
  position: relative;
  color: #fff;
  margin-bottom: ${theme.spacing("l")};
  z-index: 5;
`;

const RefSpan = styled.span`
  display: inline-block;
  position: relative;
`;

const scrollTo = (ref, yOffset = 0) => {
  if (ref.current) {
    window.scrollTo({
      top:
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset,
      behavior: "smooth",
    });
  }
};

const HeroHome = ({ title, heroImage, text, cta, ...props }) => {
  const backgroundRef = useRef();
  const mainTitleRef = useRef();
  const imageRef = useRef();
  const buttonRef = useRef();
  const repeatingTitleRef = useRef([]);

  return (
    <Wrapper>
      <Background ref={backgroundRef} />
      <ImageWrapper ref={imageRef}>
        <ParallaxImage1 scrollOffset={["-20%", "20%"]}>
          <ImageComponent
            image={{ data: heroImage?.greyscale }}
            layout="fill"
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </ParallaxImage1>
      </ImageWrapper>
      <StyledContentWrapper columns={12} {...props}>
        <TitleWrapper>
          <TitleRepeatWrapper>
            {[...Array(4)].map((x, i) => (
              <RepeatedHeadingWrapper key={i}>
                <StyledStrokedHeading
                  as="span"
                  size={1}
                  style={{ opacity: (i + 1) * 0.25 }}
                >
                  <RefSpan ref={(el) => (repeatingTitleRef.current[i] = el)}>
                    {title}
                  </RefSpan>
                </StyledStrokedHeading>
              </RepeatedHeadingWrapper>
            ))}
          </TitleRepeatWrapper>
          <StyledFirstHeading size={1}>
            <RefSpan ref={mainTitleRef}>{title}</RefSpan>{" "}
          </StyledFirstHeading>
        </TitleWrapper>
        <CtaWrapper>
          <RefSpan ref={buttonRef}>
            <RotatingButton
              appearance="light"
              label={cta.label}
              arrowDirection="down"
              onClick={() => scrollTo(cta.refElement)}
            />
          </RefSpan>
        </CtaWrapper>
      </StyledContentWrapper>
      {text && (
        <TextContentWrapper columns={8}>
          <TextContainer>
            <TextWrapper>
              <StyledText html>{text}</StyledText>
            </TextWrapper>
          </TextContainer>
        </TextContentWrapper>
      )}
    </Wrapper>
  );
};

HeroHome.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: shape({ greyscale: ImageDataType }),
  cta: PropTypes.object,
  text: PropTypes.node.isRequired,
};

export default HeroHome;
