import styled from "@emotion/styled";
import { node } from "prop-types";
import React, { useEffect, useState } from "react";
import LottieAnimation from "react-lottie-player";

const StyledLottiePlayer = styled(LottieAnimation)`
  width: 150px;
  height: 150px;
  position: relative;
`;

const Lottie = ({ data, ...props }) => {
  const [animationData, setAnimationData] = useState(false);

  useEffect(() => {
    fetch(data)
      .then((response) => response.json())
      .then((json) => {
        setAnimationData(json);
      });
  }, [data]);

  return animationData ? (
    <StyledLottiePlayer animationData={animationData} {...props} />
  ) : null;
};

Lottie.propTypes = {
  data: node,
};

export default Lottie;
