import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing("xxxs")};
`;

const ProductIcon = styled.img`
  height: 1.2em;
  width: 1.2em;
`;

function ProductWithIcon({ product, ...props }) {
  return (
    <Wrapper {...props} style={{ color: product.iconColor.hex }}>
      {product.title}{" "}
      <ProductIcon
        src={product.icon.url}
        style={{ fill: product.iconColor.hex }}
      />
    </Wrapper>
  );
}

ProductWithIcon.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductWithIcon;
