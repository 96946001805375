import styled from "@emotion/styled";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { map, prop } from "ramda";
import React, { useRef } from "react";

import CaseCarousel from "../components/CaseCarousel";
import ContactBasic from "../components/ContactBasic";
import ContentWrapper from "../components/ContentWrapper";
import Hero from "../components/Hero/Home";
import Partners from "../components/Partners";
import ProductsGrid from "../components/ProductsGrid";
import SEO from "../components/SEO";
import VideoBanner from "../components/VideoBanner";
import { CASES_PATH, PRODUCTS_PATH } from "../constants";
import theme from "../theme";
import { homepage } from "../types";

const VerticalMargin = styled.div`
  margin-top: ${theme.space("xl")};
  margin-bottom: ${theme.space("xl")};
`;

const formatCaseProps = map(({ slug, thumbnail, ...caseData }) => {
  return {
    ...caseData,
    slug: `${CASES_PATH}/${slug}`,
    image: {
      data: thumbnail.highlight,
    },
  };
});

const formatProductProps = map(({ slug, ...product }) => {
  return {
    ...product,
    slug: `${PRODUCTS_PATH}/${slug}`,
  };
});

const IndexPage = ({
  data: {
    data: { homepage },
  },
}) => {
  const caseCarouselRef = useRef(null);

  return (
    <>
      <SEO tags={prop("seoMetaTags", homepage)} />
      <Hero
        title={prop("heroTitle", homepage)}
        heroImage={prop("heroImage", homepage)}
        targetAreas={prop("heroTargetAreas", homepage)}
        images={prop("heroImages", homepage)}
        text={prop("heroIntro", homepage)}
        cta={{
          label: prop("heroActionText", homepage),
          refElement: caseCarouselRef,
        }}
      />
      <ProductsGrid
        title={prop("productsTitle", homepage)}
        intro={prop("productsIntro", homepage)}
        cta={{
          label: prop("productsActionText", homepage),
          path: prop("productsActionPath", homepage),
        }}
        products={formatProductProps(prop("products", homepage))}
      />
      <CaseCarousel
        ref={caseCarouselRef}
        title={prop("casesTitle", homepage)}
        cases={formatCaseProps(prop("cases", homepage))}
        cta={{
          label: prop("casesActionText", homepage),
          path: prop("casesActionPath", homepage),
        }}
      />
      <VideoBanner
        title={prop("videoloopTitle", homepage)}
        video={prop("videoloop", homepage)}
        cta={{
          label: prop("videoloopActionText", homepage),
          path: prop("videoloopActionPath", homepage),
        }}
      />
      <ContactBasic
        title={prop("contactTitle", homepage)}
        body={prop("contactIntro", homepage)}
        cta={{
          label: prop("contactActionText", homepage),
          path: prop("contactActionPath", homepage),
        }}
      />
      <VerticalMargin>
        <ContentWrapper columns={12}>
          <Partners
            partnersTitle={prop("partnersTitle", homepage)}
            partners={prop("partners", homepage)}
            honorsTitle={prop("honorTitle", homepage)}
            honors={prop("honorPartners", homepage)}
          />
        </ContentWrapper>
      </VerticalMargin>
    </>
  );
};

export const query = graphql`
  query NewHomepageQuery {
    data: dato {
      homepage: newHomepage {
        #* SEO *#
        seoMetaTags: _seoMetaTags {
          tag
          attributes
          content
        }
        #* Hero *#
        heroTitle
        heroImage {
          greyscale: responsiveImage(
            imgixParams: {
              fit: crop
              w: 700
              h: 780
              auto: format
              duotone: "000000,ffffff"
              duotoneAlpha: 100
            }
          ) {
            ...responsiveImageFragment
          }
        }
        heroActionText
        heroIntro
        #* Cases *#
        casesTitle
        cases {
          ...caseFragment
        }
        casesActionText
        casesActionPath
        #* Video *#
        videoloopTitle
        videoloop {
          ...MuxVideoFragment
        }
        videoloopActionText
        videoloopActionPath
        #* Products *#
        productsTitle
        productsIntro
        products {
          ...productFragment
        }
        productsActionText
        productsActionPath
        contactTitle
        contactIntro
        contactActionText
        contactActionPath
        partnersTitle
        partners {
          id
          title
          logo {
            title
            alt
            url
            data: responsiveImage(
              imgixParams: { fit: clip, w: 136, h: 136, auto: format }
            ) {
              ...responsiveImageFragment
            }
          }
        }
        honorTitle
        honorPartners {
          id
          title
          logo {
            title
            alt
            url
            data: responsiveImage(
              imgixParams: { fit: clip, w: 136, h: 136, auto: format }
            ) {
              ...responsiveImageFragment
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      homepage: PropTypes.shape(homepage).isRequired,
    }).isRequired,
  }).isRequired,
};

export default IndexPage;
